import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

// Custom components
import Title from "../../../../general/Title";
import TopBarForm from "../TopBarForm";
import InstructionDialog from "../InstructionDialog";

// Mui core
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Typography from "@mui/material/Typography";
import RadioGroup from "@mui/material/RadioGroup";
import IconButton from "@mui/material/IconButton";

// Hooks
import { useFetchUser } from "../../../../../hooks/useFetchUser";

// Validation
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Utils
import { dirtyValues, removeUndefinedInObject } from "../../../../../utils/general";
import { getScoreByQuestion } from "../../../../../utils/score";

// Interfaces
import { TextField } from "@mui/material";

// Icons
import { GrCircleInformation } from "react-icons/gr";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Participant } from "../../../../../interfaces/Participant";

const requiredMessage = "Required field";

interface Props {
    // eslint-disable-next-line
    onComplete: (title: string, data: any, points: number, topic: string, participant?: Participant | undefined) => void;
}

const TITLE_FR = "Vos activités physiques";
const TITLE_EN = "Your Physical Activity";
const ABOUT_FR =
    "Ce questionnaire nous aidera à mieux vous connaître et à suivre votre progression. Dix minutes devraient être nécessaires pour le compléter. Essayez de terminer le questionnaire en une seule séance.";
const ABOUT_EN =
    "These questions will help us to know you better and see your progress. This should take about 10 minutes. Try to complete this task in one session.";
const INSTRUCTION_FR = "Répondez à chaque question au mieux de vos capacités.";
const INSTRUCTION_EN = "Answer each question to the best of your abilities.";

const PhysicalActivityForm: React.FC<Props> = ({ onComplete }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { setLoading } = useFetchUser();

    // States
    const [skipTo6, setSkipTo6] = useState(true);
    const [skipTo10, setSkipTo10] = useState(true);
    const [skipTo14, setSkipTo14] = useState(true);
    const [q5Checked, setQ5Checked] = useState(false);
    const [q9Checked, setQ9Checked] = useState(false);
    const [q13Checked, setQ13Checked] = useState(false);
    const [q15Checked, setQ15Checked] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
    const [isSafeToReset, setIsSafeToReset] = useState(false);

    const generateSchema = () => {
        const output: any = {};
        output.question3 = yup.string().required(requiredMessage);
        if (!skipTo6) {
            output.question4 = yup.number().min(1).max(7).required(requiredMessage);
            output.question5 = yup.number().min(0).max(1440).required(requiredMessage);
        }
        output.question7 = yup.string().required(requiredMessage);
        if (!skipTo10) {
            output.question8 = yup.number().min(1).max(7).required(requiredMessage);
            output.question9 = yup.number().min(0).max(1440).required(requiredMessage);
        }
        output.question11 = yup.string().required(requiredMessage);
        if (!skipTo14) {
            output.question12 = yup.number().min(1).max(7).required(requiredMessage);
            output.question13 = yup.number().min(0).max(1440).required(requiredMessage);
        }
        output.question15 = yup.string().required(requiredMessage);

        return yup.object(output);
    };

    const generateDefaultValues = () => {
        const output: any = {};
        output.question3 = "";
        if (!skipTo6) {
            output.question4 = "";
            output.question5 = "";
        }
        output.question7 = "";
        if (!skipTo10) {
            output.question8 = "";
            output.question9 = "";
        }
        output.question11 = "";
        if (!skipTo14) {
            output.question12 = "";
            output.question13 = "";
        }
        output.question15 = "";

        return output;
    };

    // Forms
    const {
        reset,
        handleSubmit,
        formState: { errors, dirtyFields },
        control,
    } = useForm({ resolver: yupResolver(generateSchema()), defaultValues: generateDefaultValues() });

    const onSubmit = async (data: any) => {
        try {
            let points = 0;
            data = removeUndefinedInObject(dirtyValues(dirtyFields, data));
            setLoading(true);

            Object.entries(dirtyValues(dirtyFields, data)).forEach(e => {
                const localPoints = getScoreByQuestion("Physical", parseInt((e[0] as string).replace("question", "")), dirtyValues(dirtyFields, data));

                if (typeof localPoints !== "undefined" && localPoints !== "n/a") points += localPoints as number;
            });

            enqueueSnackbar(t("questionnaireCompleted", { title: localStorage.getItem("language") === "en" ? TITLE_EN : TITLE_FR }), {
                variant: "success",
            });
            onComplete(TITLE_EN, dirtyValues(dirtyFields, data), points, "Physical", dirtyValues(dirtyFields, data));
            window.scrollTo(0, 0);
            setIsSafeToReset(true);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (Object.entries(errors).length > 0) {
            const id = Object.entries(errors)[0][0];
            const element = document.getElementById(id);

            if (element) element.scrollIntoView({ block: "center" });
        }
    }, [errors]);

    useEffect(() => {
        if (isSafeToReset) reset();
    }, [isSafeToReset]);

    return (
        <>
            <TopBarForm />
            <div className="forms__container">
                <Title>{localStorage.getItem("language") === "en" ? TITLE_EN : TITLE_FR}</Title>

                <div className="forms__section">
                    <h6 className="forms__subtitle">{localStorage.getItem("language") === "en" ? "About" : "À propos"}</h6>
                    <p>{localStorage.getItem("language") === "en" ? ABOUT_EN : ABOUT_FR}</p>
                </div>

                <div className="forms__section">
                    <h6 className="forms__subtitle">Instructions</h6>
                    <p>{localStorage.getItem("language") === "en" ? INSTRUCTION_EN : INSTRUCTION_FR}</p>
                </div>

                <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)} className="forms__content">
                    <Grid item>
                        <div>
                            <div className="forms__important__container">
                                <br />
                                <br />
                                <h6 className="forms__important">
                                    &emsp;
                                    {localStorage.getItem("language") === "en"
                                        ? "We are interested in finding out about the kinds of physical activities that people do as part of their everyday lives. The questions will ask you about the time you spent being physically active in the last 7 days. Please answer each question even if you do not consider yourself to be an active person. Please think about the activities you do at work, as part of your house and yard work, to get from place to place, and in your spare time for recreation, exercise or sport."
                                        : "Nous souhaitons connaître le genre d’activités physiques que les gens font dans leur vie quotidienne. Les questions porteront sur le temps que vous avez passé à faire de l’activité physique au cours des sept derniers jours. Veuillez répondre à chaque question même si vous ne vous considérez pas comme une personne active. Pensez aux activités physiques que vous faites au travail, à vos tâches ménagères ou votre jardinage, vos déplacements à pied, et à vos moments libres que vous consacrez aux loisirs et à faire de l’exercice ou du sport."}
                                </h6>
                            </div>

                            <div className="forms__important__container">
                                <h6 className="forms__important">
                                    &emsp;
                                    {localStorage.getItem("language") === "en"
                                        ? "Think about all the vigorous activities that you did in the last 7 days. Vigorous physical activities refer to activities that take hard physical effort and make you breathe much harder than normal. Think only about those physical activities that you did for at least 10 minutes at a time.  "
                                        : "Pensez à toutes les activités vigoureuses que vous avez faites au cours des 7 derniers jours. Les activités physiques vigoureuses désignent les activités qui demandent un effort physique intense et qui vous font respirer beaucoup plus fort que la normale. Ne pensez qu'aux activités physiques que vous avez faites pendant au moins 10 minutes à la fois.  "}
                                </h6>
                            </div>

                            <Controller
                                control={control}
                                name="question3"
                                render={({ field }) => (
                                    <FormControl
                                        id="question3"
                                        component="fieldset"
                                        error={!!errors.question3?.message}
                                        {...field}
                                        onChange={(e: any) => {
                                            field.onChange(e);
                                            setSkipTo6(e.target.value === "yes" ? false : true);
                                        }}
                                    >
                                        <FormLabel component="legend">
                                            {localStorage.getItem("language") === "en"
                                                ? "During the last 7 days, did you do vigorous physical activities like heavy lifting, digging, aerobics, or fast bicycling?"
                                                : "Au cours des 7 derniers jours, avez-vous fait des activités physiques vigoureuses comme soulever des charges lourdes, creuser, faire de l’exercice aérobique ou faire du vélo rapide?"}
                                            <IconButton
                                                color="primary"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    setSelectedTypes(["radio"]);
                                                    setDialogOpen(true);
                                                }}
                                            >
                                                <GrCircleInformation />
                                            </IconButton>
                                        </FormLabel>
                                        <RadioGroup row>
                                            <FormControlLabel
                                                value="yes"
                                                control={<Radio />}
                                                label={localStorage.getItem("language") === "en" ? "Yes" : "Oui"}
                                            />
                                            <FormControlLabel
                                                value="no"
                                                control={<Radio />}
                                                label={localStorage.getItem("language") === "en" ? "No" : "Non"}
                                            />
                                        </RadioGroup>
                                        <FormHelperText style={{ fontSize: 20 }}>{errors.question3?.message}</FormHelperText>
                                    </FormControl>
                                )}
                            />

                            {!skipTo6 && (
                                <>
                                    <Controller
                                        control={control}
                                        name="question4"
                                        render={({ field }) => (
                                            <FormControl id="question4" component="fieldset" error={!!errors.question4?.message} {...field}>
                                                <FormLabel component="legend">
                                                    {localStorage.getItem("language") === "en"
                                                        ? "During the last 7 days, on how many days did you do vigorous physical activities like heavy lifting, digging, aerobics, or fast bicycling?"
                                                        : "Au cours des 7 derniers jours, combien de jours avez-vous fait des activités physiques vigoureuses comme soulever des charges lourdes, creuser, faire de l'exercice aérobique ou du vélo rapide?"}
                                                    <IconButton
                                                        color="primary"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setSelectedTypes(["input"]);
                                                            setDialogOpen(true);
                                                        }}
                                                    >
                                                        <GrCircleInformation />
                                                    </IconButton>
                                                </FormLabel>
                                                <TextField
                                                    className="forms__numberInput"
                                                    type="number"
                                                    InputProps={{ inputProps: { min: 0, max: 7 } }}
                                                    variant="standard"
                                                    helperText={errors.question4?.message}
                                                    error={!!errors.question4?.message}
                                                    label={localStorage.getItem("language") === "en" ? "Number of days" : "Nombre de jours"}
                                                    {...field}
                                                />
                                            </FormControl>
                                        )}
                                    />

                                    <Controller
                                        control={control}
                                        name="question5"
                                        render={({ field }) => (
                                            <FormControl id="question5" component="fieldset" error={!!errors.question5?.message} {...field}>
                                                <FormLabel component="legend">
                                                    {localStorage.getItem("language") === "en"
                                                        ? "How much time did you usually spend doing vigorous physical activities on one of those days?"
                                                        : "Combien de temps avez-vous passé en moyenne à faire des activités physiques vigoureuses lors d'une de ces journées?"}
                                                    <IconButton
                                                        color="primary"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setSelectedTypes(["input", "radio"]);
                                                            setDialogOpen(true);
                                                        }}
                                                    >
                                                        <GrCircleInformation />
                                                    </IconButton>
                                                </FormLabel>
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            type="number"
                                                            InputProps={{ inputProps: { min: 0, max: 1440 } }}
                                                            variant="standard"
                                                            helperText={errors.question5?.message}
                                                            error={!!errors.question5?.message}
                                                            label={<Typography variant="h6">Minutes</Typography>}
                                                            {...field}
                                                            onChange={(e: any) => {
                                                                field.onChange(e);
                                                                setQ5Checked(e.target.checked);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FormControlLabel
                                                            value="0"
                                                            control={<Radio checked={q5Checked} onChange={e => setQ5Checked(e.target.checked)} />}
                                                            label={
                                                                localStorage.getItem("language") === "en"
                                                                    ? "Don’t Know / Not Sure"
                                                                    : "Ne sait pas / N’est pas sûr"
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </FormControl>
                                        )}
                                    />
                                </>
                            )}
                            <div className="forms__important__container">
                                <h6 className="forms__important">
                                    &emsp;
                                    {localStorage.getItem("language") === "en"
                                        ? "Think about all the moderate activities that you did in the last 7 days. Moderate activities refer to activities that take moderate physical effort and make you breathe somewhat harder than normal. Think only about those physical activities that you did for at least 10 minutes at a time."
                                        : "Pensez à toutes les activités modérées que vous avez faites au cours des 7 derniers jours. Les activités modérées sont des activités qui exigent un effort physique modéré et qui vous font respirer un peu plus fort que la normale. Pensez seulement aux activités physiques que vous avez faites sur une durée d’au moins 10 minutes à la fois."}
                                </h6>
                            </div>

                            <Controller
                                control={control}
                                name="question7"
                                render={({ field }) => (
                                    <FormControl
                                        id="question7"
                                        component="fieldset"
                                        error={!!errors.question7?.message}
                                        {...field}
                                        onChange={(e: any) => {
                                            field.onChange(e);
                                            setSkipTo10(e.target.value === "yes" ? false : true);
                                        }}
                                    >
                                        <FormLabel component="legend">
                                            {localStorage.getItem("language") === "en"
                                                ? "During the last 7 days, did you do moderate physical activities like carrying light loads, bicycling at a regular pace, or doubles tennis? Do not include walking.  "
                                                : "Au cours des 7 derniers jours, avez-vous fait des activités physiques modérées, telles que transporter des charges légères, faire du vélo à un rythme régulier ou faire du tennis en double? N’incluez pas la marche. "}
                                            <IconButton
                                                color="primary"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    setSelectedTypes(["radio"]);
                                                    setDialogOpen(true);
                                                }}
                                            >
                                                <GrCircleInformation />
                                            </IconButton>
                                        </FormLabel>
                                        <RadioGroup row>
                                            <FormControlLabel
                                                value="yes"
                                                control={<Radio />}
                                                label={localStorage.getItem("language") === "en" ? "Yes" : "Oui"}
                                            />
                                            <FormControlLabel
                                                value="no"
                                                control={<Radio />}
                                                label={localStorage.getItem("language") === "en" ? "No" : "Non"}
                                            />
                                        </RadioGroup>
                                        <FormHelperText style={{ fontSize: 20 }}>{errors.question7?.message}</FormHelperText>
                                    </FormControl>
                                )}
                            />

                            {!skipTo10 && (
                                <>
                                    <Controller
                                        control={control}
                                        name="question8"
                                        render={({ field }) => (
                                            <FormControl id="question8" component="fieldset" error={!!errors.question8?.message} {...field}>
                                                <FormLabel component="legend">
                                                    {localStorage.getItem("language") === "en"
                                                        ? "During the last 7 days, on how many days did you do moderate physical activities like carrying light loads, bicycling at a regular pace, or doubles tennis? Do not include walking."
                                                        : "Au cours des 7 derniers jours, combien de jours avez-vous fait des activités physiques modérées, telles que transporter des charges légères, faire du vélo à un rythme régulier ou faire du tennis en double? N’incluez pas la marche. "}
                                                    <IconButton
                                                        color="primary"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setSelectedTypes(["input"]);
                                                            setDialogOpen(true);
                                                        }}
                                                    >
                                                        <GrCircleInformation />
                                                    </IconButton>
                                                </FormLabel>
                                                <TextField
                                                    className="forms__numberInput"
                                                    type="number"
                                                    InputProps={{ inputProps: { min: 0, max: 7 } }}
                                                    variant="standard"
                                                    helperText={errors.question8?.message}
                                                    error={!!errors.question8?.message}
                                                    label={localStorage.getItem("language") === "en" ? "Number of days" : "Nombre de jours"}
                                                    {...field}
                                                />
                                            </FormControl>
                                        )}
                                    />

                                    <Controller
                                        control={control}
                                        name="question9"
                                        render={({ field }) => (
                                            <FormControl id="question9" component="fieldset" error={!!errors.question9?.message} {...field}>
                                                <FormLabel component="legend">
                                                    {localStorage.getItem("language") === "en"
                                                        ? "How much time did you usually spend doing moderate physical activities on one of those days?  "
                                                        : "Combien de temps avez-vous passé en moyenne à faire des activités physiques modérées lors d'une de ces journées? "}
                                                    <IconButton
                                                        color="primary"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setSelectedTypes(["radio", "input"]);
                                                            setDialogOpen(true);
                                                        }}
                                                    >
                                                        <GrCircleInformation />
                                                    </IconButton>
                                                </FormLabel>
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            type="number"
                                                            InputProps={{ inputProps: { min: 0, max: 1440 } }}
                                                            variant="standard"
                                                            helperText={errors.question9?.message}
                                                            error={!!errors.question9?.message}
                                                            label={<Typography variant="h6">Minutes</Typography>}
                                                            {...field}
                                                            onChange={(e: any) => {
                                                                field.onChange(e);
                                                                setQ9Checked(e.target.checked);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FormControlLabel
                                                            value="0"
                                                            control={<Radio checked={q9Checked} onChange={e => setQ9Checked(e.target.checked)} />}
                                                            label={
                                                                localStorage.getItem("language") === "en"
                                                                    ? "Don’t Know / Not Sure"
                                                                    : "Ne sait pas / N’est pas sûr"
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </FormControl>
                                        )}
                                    />
                                </>
                            )}
                            <div className="forms__important__container">
                                <h6 className="forms__important">
                                    &emsp;
                                    {localStorage.getItem("language") === "en"
                                        ? "Think about the time you spent walking in the last 7 days. This includes at work and at home, walking to travel from place to place, and any other walking that you have done solely for recreation, sport, exercise, or leisure."
                                        : "Pensez au temps que vous avez passé à marcher au cours des 7 derniers jours. Cela comprend les pas que vous avez faits au travail et à la maison, tous vos déplacements à pied, et toutes les marches que vous avez faites exclusivement dans le cadre de vos activités récréatives, sportives, ou de loisirs."}
                                </h6>
                            </div>

                            <Controller
                                control={control}
                                name="question11"
                                render={({ field }) => (
                                    <FormControl
                                        id="question11"
                                        component="fieldset"
                                        error={!!errors.question11?.message}
                                        {...field}
                                        onChange={(e: any) => {
                                            field.onChange(e);
                                            setSkipTo14(e.target.value === "yes" ? false : true);
                                        }}
                                    >
                                        <FormLabel component="legend">
                                            {localStorage.getItem("language") === "en"
                                                ? "During the last 7 days, did you walk for at least 10 minutes at a time?"
                                                : "Au cours des 7 derniers jours, avez-vous marché pendant au moins 10 minutes à la fois?"}
                                            <IconButton
                                                color="primary"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    setSelectedTypes(["radio"]);
                                                    setDialogOpen(true);
                                                }}
                                            >
                                                <GrCircleInformation />
                                            </IconButton>
                                        </FormLabel>
                                        <RadioGroup row>
                                            <FormControlLabel
                                                value="yes"
                                                control={<Radio />}
                                                label={localStorage.getItem("language") === "en" ? "Yes" : "Oui"}
                                            />
                                            <FormControlLabel
                                                value="no"
                                                control={<Radio />}
                                                label={localStorage.getItem("language") === "en" ? "No" : "Non"}
                                            />
                                        </RadioGroup>
                                        <FormHelperText style={{ fontSize: 20 }}>{errors.question11?.message}</FormHelperText>
                                    </FormControl>
                                )}
                            />

                            {!skipTo14 && (
                                <>
                                    <Controller
                                        control={control}
                                        name="question12"
                                        render={({ field }) => (
                                            <FormControl id="question12" component="fieldset" error={!!errors.question12?.message} {...field}>
                                                <FormLabel component="legend">
                                                    {localStorage.getItem("language") === "en"
                                                        ? "During the last 7 days, on how many days did you walk for at least 10 minutes at a time?"
                                                        : "Au cours des 7 derniers jours, combien de jours avez-vous marché pendant au moins 10 minutes à la fois? "}
                                                    <IconButton
                                                        color="primary"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setSelectedTypes(["input"]);
                                                            setDialogOpen(true);
                                                        }}
                                                    >
                                                        <GrCircleInformation />
                                                    </IconButton>
                                                </FormLabel>
                                                <TextField
                                                    className="forms__numberInput"
                                                    type="number"
                                                    InputProps={{ inputProps: { min: 0, max: 7 } }}
                                                    variant="standard"
                                                    helperText={errors.question12?.message}
                                                    error={!!errors.question12?.message}
                                                    label={localStorage.getItem("language") === "en" ? "Number of days" : "Nombre de jours"}
                                                    {...field}
                                                />
                                            </FormControl>
                                        )}
                                    />

                                    <Controller
                                        control={control}
                                        name="question13"
                                        render={({ field }) => (
                                            <FormControl id="question13" component="fieldset" error={!!errors.question13?.message} {...field}>
                                                <FormLabel component="legend">
                                                    {localStorage.getItem("language") === "en"
                                                        ? "How much time did you usually spend walking on one of those days?"
                                                        : "Combien de temps avez-vous passé à marcher lors d'une de ces journées? "}
                                                    <IconButton
                                                        color="primary"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setSelectedTypes(["radio", "input"]);
                                                            setDialogOpen(true);
                                                        }}
                                                    >
                                                        <GrCircleInformation />
                                                    </IconButton>
                                                </FormLabel>
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            type="number"
                                                            InputProps={{ inputProps: { min: 0, max: 1440 } }}
                                                            variant="standard"
                                                            helperText={errors.question13?.message}
                                                            error={!!errors.question13?.message}
                                                            label={<Typography variant="h6">Minutes</Typography>}
                                                            {...field}
                                                            onChange={(e: any) => {
                                                                field.onChange(e);
                                                                setQ13Checked(e.target.checked);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FormControlLabel
                                                            value="0"
                                                            control={<Radio checked={q13Checked} onChange={e => setQ13Checked(e.target.checked)} />}
                                                            label={
                                                                localStorage.getItem("language") === "en"
                                                                    ? "Don’t Know / Not Sure"
                                                                    : "Ne sait pas / N’est pas sûr"
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </FormControl>
                                        )}
                                    />
                                </>
                            )}

                            <div className="forms__important__container">
                                <h6 className="forms__important">
                                    &emsp;
                                    {localStorage.getItem("language") === "en"
                                        ? "The last question is about the time you spent sitting on weekdays during the last 7 days. Include time spent at work, at home, while doing course work and during leisure time. This may include time spent sitting at a desk, visiting friends, reading, or sitting or lying down to watch television."
                                        : "La dernière question porte sur le temps que vous avez passé assis au cours des jours de semaine dans les sept derniers jours. Incluez le temps passé assis au travail, à la maison, lors de formations ou pendant vos loisirs. Cela peut inclure le temps passé assis à un bureau, lorsque vous rendez visite à des amis, que vous lisez, ou que vous êtes assis ou couché pour regarder la télévision."}
                                </h6>
                            </div>

                            <Controller
                                control={control}
                                name="question15"
                                render={({ field }) => (
                                    <FormControl id="question15" component="fieldset" error={!!errors.question15?.message} {...field}>
                                        <FormLabel component="legend">
                                            {localStorage.getItem("language") === "en"
                                                ? "During the last 7 days, how much time did you spend sitting on a week day?"
                                                : "Au cours des 7 derniers jours, combien de temps avez-vous passé assis un jour de semaine ?"}
                                            <IconButton
                                                color="primary"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    setSelectedTypes(["radio", "input"]);
                                                    setDialogOpen(true);
                                                }}
                                            >
                                                <GrCircleInformation />
                                            </IconButton>
                                        </FormLabel>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <TextField
                                                    type="number"
                                                    InputProps={{ inputProps: { min: 0, max: 24 } }}
                                                    variant="standard"
                                                    helperText={errors.question13?.message}
                                                    error={!!errors.question13?.message}
                                                    label={localStorage.getItem("language") === "en" ? "Hours per day" : "Heures par jour"}
                                                    {...field}
                                                    onChange={(e: any) => {
                                                        field.onChange(e);
                                                        setQ15Checked(e.target.checked);
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControlLabel
                                                    value="0"
                                                    control={<Radio checked={q15Checked} onChange={e => setQ15Checked(e.target.checked)} />}
                                                    label={
                                                        localStorage.getItem("language") === "en"
                                                            ? "Don’t Know / Not Sure"
                                                            : "Ne sait pas / N’est pas sûr"
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                        <FormHelperText style={{ fontSize: 20 }}>{errors.question15?.message}</FormHelperText>
                                    </FormControl>
                                )}
                            />
                        </div>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" type="submit" className="questionnaires__cta" fullWidth>
                            {localStorage.getItem("language") === "en" ? "Submit" : "Soumettre"} <MdKeyboardArrowRight />
                        </Button>
                    </Grid>
                    <div id="spacer_for_button" style={{ minHeight: 30 }} />
                </form>
            </div>
            <InstructionDialog open={dialogOpen} setOpen={setDialogOpen} types={selectedTypes} />
        </>
    );
};

export default PhysicalActivityForm;
