import { Route as ReactRoute, RouteProps, useHistory } from "react-router-dom";

// Hooks
import { useFetchUser } from "../hooks/useFetchUser";

// Settings
import settings from "../settings.json";
import Unauthorized from "../components/pages/portal/Unauthorized";

// Components
import DashboardLogin from "../components/pages/dashboard/Login";
import Landing from "../components/pages/Landing";

export interface CustomRouteProps {
    Component: React.FC;
    readonly isPrivate?: boolean;
    readonly roles?: string[];
    readonly rolesAllowed?: string[];
    readonly title?: string;
    [index: string]: any; // rest
}

const Route: React.FC<CustomRouteProps> = ({
    Component,
    isPrivate = true,
    LayoutComponent = null,
    roles = [],
    rolesAllowed = [],
    title,
    ...rest
}) => {
    const hist = useHistory();
    const { currentAdmin, currentParticipant } = useFetchUser();

    if (title) document.title = `${title} - ${settings.app.title}`;

    if (!currentAdmin) {
        if (hist.location.pathname.includes("/dashboard")) return <DashboardLogin />;
        else if (
            !currentParticipant &&
            hist.location.pathname !== "/" &&
            hist.location.pathname !== "/login" &&
            hist.location.pathname !== "/register" &&
            hist.location.pathname !== "/accountHelp"
        ) {
            return <Landing />;
        }
    }

    if (currentAdmin) roles = currentAdmin.roles;

    if (
        !rest.path.includes("/dashboard") &&
        !rest.path.includes("/login") &&
        !rest.path.includes("/register") &&
        !rest.path.includes("/accountHelp")
    ) {
        if (!currentParticipant) return <Landing />;
    }

    if (rest.path.includes("/dashboard")) {
        if (
            (currentAdmin && currentAdmin.roles && currentAdmin.roles.length === 0) ||
            (rolesAllowed.length > 0 && !!roles.length && !rolesAllowed.some(r => roles.includes(r))) ||
            (currentAdmin && currentAdmin.isDeleted)
        ) {
            // deny access to user not having the appropriate roles
            return <Unauthorized />;
        }
    }

    return (
        <ReactRoute
            {...rest}
            render={(routeProps: RouteProps) => {
                if (!isPrivate) {
                    if (LayoutComponent) {
                        return (
                            <LayoutComponent>
                                <Component {...routeProps} />
                            </LayoutComponent>
                        );
                    } else {
                        return <Component {...routeProps} />;
                    }
                }
                return (
                    <LayoutComponent>
                        <Component {...routeProps} />
                    </LayoutComponent>
                );
            }}
        />
    );
};

export default Route;
