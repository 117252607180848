// React
import { NavLink } from "react-router-dom";

// Mui
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
// import ListSubheader from "@mui/material/ListSubheader";

// Firebase
import { auth } from "../../../firebase/firebase";

// Icons
import { MdHome, MdPeople, MdPowerSettingsNew, MdAdminPanelSettings, MdQueryStats } from "react-icons/md";
import { GiMagnifyingGlass } from "react-icons/gi";
import { GoChecklist } from "react-icons/go";

// Hooks
import { useFetchUser } from "../../../hooks/useFetchUser";

type DrawerItemProps = { to: string; onClick?: any; icon?: React.FC; primaryText: string; color?: string };

const DrawerItem: React.FC<DrawerItemProps> = (props: DrawerItemProps) => {
    return (
        <NavLink to={props.to} onClick={() => props.onClick ?? null} exact className="drawer__nav__item">
            <ListItem button>
                <ListItemIcon>{props.icon ? <props.icon /> : null}</ListItemIcon>
                <ListItemText primary={props.primaryText} color={props.color ?? "primary"} />
            </ListItem>
        </NavLink>
    );
};

const DrawerContent = () => {
    const { currentAdmin, resetStates } = useFetchUser();

    return (
        <div className="dashboard">
            <div className="drawer__nav">
                <List>
                    {currentAdmin &&
                        (currentAdmin.roles.includes("syn2") ||
                            currentAdmin.roles.includes("admin") ||
                            currentAdmin.roles.includes("superAdmin")) && (
                            <>
                                <DrawerItem to="/dashboard" icon={MdHome} primaryText="Home" />
                                <DrawerItem to="/dashboard/whitelist" icon={GoChecklist} primaryText="Whitelist" />
                                <DrawerItem to="/dashboard/stats" icon={MdQueryStats} primaryText="Stats" />
                            </>
                        )}
                    {currentAdmin &&
                        (currentAdmin.roles.includes("syn2") ||
                            currentAdmin.roles.includes("admin") ||
                            currentAdmin.roles.includes("superAdmin")) && (
                            <>
                                <DrawerItem to="/dashboard/participants" icon={MdPeople} primaryText="Participants" />
                            </>
                        )}

                    {/* Requires superAdmin */}
                    {currentAdmin && currentAdmin.roles.includes("superAdmin") && (
                        <>
                            <DrawerItem to="/dashboard/chapters" icon={GoChecklist} primaryText="Chapters" />
                            <DrawerItem to="/dashboard/admins" icon={MdAdminPanelSettings} primaryText="Admins" />
                            <DrawerItem to="/dashboard/Tracking" icon={GiMagnifyingGlass} primaryText="Tracking" />
                        </>
                    )}
                </List>
                <Divider />
                <List>
                    <div
                        className="drawer__nav__item"
                        onClick={() => {
                            resetStates();
                            auth.signOut();
                        }}
                    >
                        <ListItem button>
                            <ListItemIcon>
                                <MdPowerSettingsNew />
                            </ListItemIcon>
                            <ListItemText primary="Logout" color="primary" />
                        </ListItem>
                    </div>
                </List>
            </div>
        </div>
    );
};

export default DrawerContent;
