import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Components
import GaugeCard from "../../general/GaugeCard";
import TopBarForm from "../../../components/pages/portal/forms/TopBarForm";

// Utils
import settings from "../../../settings.json";
import { getLatestStageNumber } from "../../../utils/general";
import { isChapterCompleted } from "../../../utils/chapter";

// Assets
import { MdKeyboardArrowRight } from "react-icons/md";

// Hooks
import useDb from "../../../hooks/useDb";
import { useFetchUser } from "../../../hooks/useFetchUser";

// Interfaces
import { Stat } from "../../../interfaces/Stat";

// MUI
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Skeleton } from "@mui/material";

const MetricsOverview: React.FC = () => {
    const hist = useHistory();
    const { currentParticipant, currentWeek } = useFetchUser();
    const { getAll } = useDb<Stat>("Stats", null, currentParticipant);
    const { t } = useTranslation();

    // States
    const [stats, setStats] = useState<Stat[]>([]);

    const fetchStats = async () => {
        if (currentParticipant) {
            const stageNumber = getLatestStageNumber(currentParticipant);
            setStats(
                (await getAll({
                    filters: [
                        { field: "participantId", operator: "==", value: currentParticipant.id },
                        { field: "stage", operator: "==", value: `stage${stageNumber}` },
                    ],
                })) as Stat[]
            );
        }
    };

    const handleContinue = (e: any) => {
        e.preventDefault();
        if (currentWeek >= 3) {
            // Chapter 6.1
            if (!isChapterCompleted("dBjDojoMPDWv3gvJCDjt", currentParticipant)) {
                hist.push("/chapter/dBjDojoMPDWv3gvJCDjt");
            } else {
                hist.push("/home");
            }
        } else {
            hist.push("/prioritizedTopics");
        }
    };

    useEffect(() => {
        if (currentWeek !== 0) fetchStats();
    }, [currentWeek]);

    return (
        <div>
            <TopBarForm />
            <div className="profile__container">
                <div className="profile__summary">
                    <h6>{t("profileDesc1")}</h6>
                </div>
                <Grid container spacing={3} className="profile__content">
                    {!stats.length && (
                        <>
                            <Grid item lg={3} xs={6}>
                                <Skeleton variant="rounded" className="gaugeCard__container" style={{ height: 350, borderRadius: 30 }} />
                            </Grid>
                            <Grid item lg={3} xs={6}>
                                <Skeleton variant="rounded" className="gaugeCard__container" style={{ height: 350, borderRadius: 30 }} />
                            </Grid>
                            <Grid item lg={3} xs={6}>
                                <Skeleton variant="rounded" className="gaugeCard__container" style={{ height: 350, borderRadius: 30 }} />
                            </Grid>
                            <Grid item lg={3} xs={6}>
                                <Skeleton variant="rounded" className="gaugeCard__container" style={{ height: 350, borderRadius: 30 }} />
                            </Grid>
                            <Grid item lg={3} xs={6}>
                                <Skeleton variant="rounded" className="gaugeCard__container" style={{ height: 350, borderRadius: 30 }} />
                            </Grid>
                            <Grid item lg={3} xs={6}>
                                <Skeleton variant="rounded" className="gaugeCard__container" style={{ height: 350, borderRadius: 30 }} />
                            </Grid>
                            <Grid item lg={3} xs={6}>
                                <Skeleton variant="rounded" className="gaugeCard__container" style={{ height: 350, borderRadius: 30 }} />
                            </Grid>
                            <Grid item lg={3} xs={6}>
                                <Skeleton variant="rounded" className="gaugeCard__container" style={{ height: 350, borderRadius: 30 }} />
                            </Grid>
                        </>
                    )}
                    {stats.map((s, i) => {
                        if (settings.app.topics.includes(s.topic))
                            return (
                                <Grid key={i} item lg={3} xs={6}>
                                    <GaugeCard topic={s.topic} score={s.score} isClickable={false} />
                                </Grid>
                            );
                    })}
                    {stats.length && (
                        <Grid item lg={3} xs={6} className="prioritizedTopic__item">
                            <div className="questionnaires__buttonContainer">
                                <Button variant="contained" onClick={handleContinue} className="questionnaires__button">
                                    {t("continue")}
                                    <MdKeyboardArrowRight />
                                </Button>
                            </div>
                        </Grid>
                    )}
                </Grid>
            </div>
        </div>
    );
};

export default MetricsOverview;
